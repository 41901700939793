/* eslint-disable import/no-anonymous-default-export */
export default {
    queryRouteList: '/routes',
  
    queryUserInfo: '/user',
    logoutUser: '/user/logout',
    loginUser: 'POST /user/login',
    loginUserGoogle: '/user/login_google',
  
    queryUser: '/user/:id',
    userByLogin: '/user/username/:username',
    userUpdateFields: 'PUT /user/:id',
    queryUserList: '/users',
    updateUser: 'Patch /user/:id',
    createUser: 'POST /user',
    removeUser: 'DELETE /user/:id',
    removeUserList: 'POST /users/delete',
  
    queryPost: '/post/:id',
    queryPostList: '/posts',
    queryPostVoting: 'POST /story/voting',
    storyStatistic: '/storys/statistic/:id',
    storyUsers: 'POST /storys/users',
    postLoad: 'POST /post/load',

    reactionLoad: '/reaction/:id',
    reactionsLoad: '/reactions',
    reactionsLoadRefresh: '/reactions/refresh',
    reactionView: 'POST /reaction/view',
    
    //reactionView: '/reaction/view/:id',

}