export const CANCEL_REQUEST_MESSAGE = 'cancel request'

export const APP_ROUTES = {
    MAIN: '/',
    LOGIN: '/login',
    POST: '/story',
    STORY_ADD: '/story/add',
    REACTION: '/reaction',
    REACTION_ADD: '/reaction/add',
    PROFILE: '/profile',
    PROFILE_SETTINGS: '/profile/settings',
    PAGE404: '/404',
    PAGE403: '/403',
}