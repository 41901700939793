import React, { useState, useEffect } from "react";
import PostUser from "../PostUser";
import api from '../../services'
//import './index.scss';

const { storyUsers } = api;

export default function StoryUsers(props) {
    const { postId } = props;
    const [users, setUsers] = useState([]);

    useEffect(() => {
      async function handleLoad () {
        await loadScope();
      }
      handleLoad();
    }, []);

    const loadScope = async () => {
      let response = await storyUsers({ story_id: postId });
      if (response.success) {
        setUsers(response.list);
      }
    }

    const userItems = users.map(user =>
      <PostUser author={user.user} scope={user.scope}/>
    );

    return (
      <div  className="content-text">
        {userItems}
      </div>
    )
}