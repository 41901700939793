import React from "react";
import { Col, Row, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const WiwHeaderTitle = ({title}) => {
    const navigate = useNavigate();
    return (
        <div className="wiwHeader">
            <Row gutter={8}>
                <Col className="align-center" span={4}>
                    <Button onClick={() => navigate(-1)} icon={<LeftOutlined />}/>
                </Col>
                <Col className="align-center" span={20}>
                    <div className="pageName">{title}</div>
                </Col>
            </Row>
        </div>
    );
}

export default WiwHeaderTitle;