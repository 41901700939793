import React from "react";
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import Voting from "./Voting";
import { APP_ROUTES } from '../../utils/constant';
import url from '../../utils/url';
//import './index.scss';

const { Footer } = Layout;

export default function WiwFooter({postId}) {
    let nextPost = parseInt(postId)+1;
    let lastPost = parseInt(postId)-1;
    let from = window.location.pathname;
    return (
      <Footer>
            <Voting postId={postId}/>
            <div><Link to={url(`${APP_ROUTES.POST}/${nextPost}`)}>NEXT POST: {nextPost}</Link></div>
            <div><Link to={url(`${APP_ROUTES.POST}/${lastPost}`)}>LAST POST: {lastPost}</Link></div>
            <div><Link to={url(`${APP_ROUTES.LOGIN}?from=${from}`)}>LOGIN</Link></div>            
      </Footer>
    )
}