import React from "react";
import { Link } from 'react-router-dom';
import { MdAddCircleOutline  } from "react-icons/md";
import { APP_ROUTES } from '../../../utils/constant';
import url from '../../../utils/url';

export default function ButtonStoryAdd() {
    return (
			<Link to={url(`${APP_ROUTES.STORY_ADD}`)}><MdAddCircleOutline className="nav-icon" /></Link>
    )
}