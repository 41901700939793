import React, { useEffect, useState } from "react";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import config from '../../../utils/config';

export default function ButtonGlaz({disabled}) {
    const [privat, setPrivat] = useState(false);
    const [disableClass, setDisableClass ] = useState('');

    useEffect(() => {
        if (localStorage.getItem(config.privat)) {
            setPrivat(localStorage.getItem(config.privat) === "true"); 
        } else {
            localStorage.setItem(config.privat, privat)
        }
        if (!!disabled) {
            setDisableClass('button-disable');
        }
    }, []);

    const togglePrivat = () => {
        if (disableClass!=='') return;
        const privat_flg = (localStorage.getItem(config.privat)) ? (localStorage.getItem(config.privat) === "true") : false;
        setPrivat(!privat_flg);
        localStorage.setItem(config.privat, !privat_flg);
    }

    return (
        <span>
            { privat === true ? 
                <HiOutlineEyeOff onClick={togglePrivat} className={`nav-icon ${disableClass}`} />    
                :
                <HiOutlineEye onClick={togglePrivat} className={`nav-icon ${disableClass}`} />
            }
        </span>
    )
}