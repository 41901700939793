import React from "react";
import { FaRegUserCircle } from 'react-icons/fa';

export default function UserImage({picture}) {
    return (
        <>
                { !!picture ? (
                <img className="nav-icon roundImage userImage" src={picture} />
                ) : (
                <FaRegUserCircle className="nav-icon userImage" />
                )
        }
        </>
    )
}