import React from "react";
import { useTranslation } from 'react-i18next';
import { Col, Row, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const PostFilter = () => {
	const { t } = useTranslation();
    return (
			<div className="wiwHeader">
				<Row gutter={8}>
					<Col className="align-center" span={6}>
						<Button icon={<SearchOutlined />}/>
					</Col>
					<Col className="align-center" span={6}>
						<Button>{t('post.filter_all')}</Button>
					</Col>
					<Col className="align-center" span={6}>
						<Button>{t('post.filter_local')}</Button>
					</Col>
					<Col className="align-center" span={6}>
						<Button>{t('post.filter_friends')}</Button>
					</Col>
				</Row>
			</div>
      )
}

export default PostFilter;