import React from "react";
import { Link } from 'react-router-dom';
import { MdOutlineRateReview } from "react-icons/md";
import { APP_ROUTES } from '../../../utils/constant';
import url from '../../../utils/url';

export default function ButtonReactionAdd({postId, disabled}) {
    return (
        <span>
            { !!disabled ?
                (<MdOutlineRateReview className="nav-icon button-disable" />)
                :
                 (<Link to={url(`${APP_ROUTES.POST}/${postId}/add`)}><MdOutlineRateReview className="nav-icon" /></Link>)
            }
        </span>
    
    )
}