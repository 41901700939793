import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Ubuntu/Ubuntu-Medium.ttf';
import './fonts/Ubuntu/Ubuntu-Regular.ttf';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //Удалить <React.StrictMode>, чтобы useEffect не вызывался два раза
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
