import React, { useEffect } from 'react';
import useFetch from "../../hooks/useFetch";
import { getLanguage } from '../../utils/func';

export default function AuthButtonGoogle() {

    const { handleLogin, loading, error } = useFetch('google');

    useEffect(() => {
        /* global google */
        if (window.google) {
          google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: handleLogin,
          });
    
          google.accounts.id.renderButton(document.getElementById("loginGoogle"), {
            type: "standard",
            theme: "outline",
            // size: "small",
            text: "signup_with",
            shape: "rectangular",
            locale: getLanguage()
          });
    
          // google.accounts.id.prompt()
        }
    }, [handleLogin]);

    return (
        <div style={{width: '100%'}}>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {loading ? <div>Loading....</div> : <div id="loginGoogle"></div>}
        </div>
    );
}