import React from "react";
import { Link } from 'react-router-dom';
import { MdLogin } from "react-icons/md";
import { APP_ROUTES } from '../../../utils/constant';
import url from '../../../utils/url';
import { getUser } from '../../../utils/func';
import UserImage from '../../UserImage';

export default function ButtonLoginOrProfile() {
    let from = window.location.pathname;
    let user = getUser();
    return (
        <span>
        { user === null ?
            (<Link to={url(`${APP_ROUTES.LOGIN}?from=${from}`)}><MdLogin className="nav-icon"/></Link>)
            :
            (<Link to={url(`${APP_ROUTES.PROFILE}/${user.user_name}`)}><UserImage picture={user.picture}/></Link>)
        }
        </span>
    )
}