import React from "react";
import { Link } from 'react-router-dom';
import { Layout, Space, Flex } from 'antd';
import ButtonHome from '../../components/Buttons/ButtonHome';
import ButtonGlaz from '../../components/Buttons/ButtonGlaz';
import ButtonStoryAdd from '../../components/Buttons/ButtonStoryAdd';
import ButtonLoginOrProfile from '../../components/Buttons/ButtonLoginOrProfile';
import WiwHeaderTitle from '../../components/WiwHeaderTitle';
import { useTranslation } from 'react-i18next';
import url from '../../utils/url';

const { Content, Footer } = Layout;

export default function Page404() {
    return (
    <Layout style={{height:"100vh"}}>
        <WiwHeaderTitle title="404"></WiwHeaderTitle>
        <Content className="content-layot">
            <Link to={url(`/`)}>Home</Link>
        </Content>
        <Footer>
            <Flex justify="space-around" align="center" >
                <ButtonHome/>
                <ButtonStoryAdd/>
                <ButtonGlaz/>
                <ButtonLoginOrProfile/>
            </Flex>
        </Footer>
    </Layout>
    )
}