import React from "react";
import { Link } from 'react-router-dom';
import { MdOutlineSettings } from "react-icons/md";
import { APP_ROUTES } from '../../../utils/constant';
import url from '../../../utils/url';

export default function ButtonSettings() {
    return (
    <Link to={url(`${APP_ROUTES.PROFILE_SETTINGS}`)}><MdOutlineSettings className="nav-icon" /></Link>
    )
}