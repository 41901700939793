import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Slider } from 'antd';
import api from '../../../services'
import './index.scss';
import variables from '../../../variable.scss';
import { APP_ROUTES } from '../../../utils/constant';
import url from '../../../utils/url';

const { queryPostVoting } = api;

export default function Voting({ postId, onChange }) {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState(4);

  const onChangeVoting = (newValue) => {
    if (window.location.pathname.includes('/add')) {
      onChange(newValue);
    } else {
      sendVoting(newValue);
    }
  };

  const sendVoting = async (score) => {
    const response = await queryPostVoting({post_id: Number(postId), score: score});
    if (response.statusCode == 401) {
      navigate(url(APP_ROUTES.LOGIN+'?from='+window.location.pathname), { replace: true });
    }
  }

  useEffect(() => {
    setInputValue(4);
    onChange(4);
  }, [postId]);

  const handleStyles = [
    {backgroundColor: variables['voting-red'], transform: "none", left: "0%"},
    {backgroundColor: variables['voting-red-light'], transform: "none", left: "14.2%"},
    {backgroundColor: variables['voting-orange'], transform: "none", left: "28.5%"},
    {backgroundColor: variables['voting-grey'], transform: "none", left: "42.8%"},
    {backgroundColor: variables['voting-light-light-green'], transform: "none", left: "57.1%"},
    {backgroundColor: variables['voting-light-green'], transform: "none", left: "71.2%"},
    {backgroundColor: variables['voting-green'], transform: "none", left: "85.4%"}
  ] 

  return (
      <Slider
        className='voting'
        min={1}
        max={7}
        onChange={(value) => setInputValue(value)}
        onChangeComplete={(value) => onChangeVoting(value)}
        tooltip={{ open: false }}
        value={typeof inputValue === 'number' ? inputValue : 0}
        handleStyle = {handleStyles[inputValue-1]}
      />
  );
}