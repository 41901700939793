import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constant';
import { BiBarChartAlt2 } from 'react-icons/bi';
import PostUser from '../../components/PostUser';
import ControlPanel from '../WiwFooter/ControlPanel';
import url from '../../utils/url';
import parse from 'html-react-parser';

export default function PostReaction(props) {
    const { reaction, finish, height, swiped, onChangeSwipe } = props;
    const navigate = useNavigate();
    const handleClick = () => {
      navigate(url(`/`), { replace: true });
    }
    

    const [height_content, setHeightContent] = useState('auto');
    const [display_content, setDisplayContent] = useState('flex');
    const [display_overflow, setDisplayOverflow] = useState('hidden');
    const [read, setRead] = useState(swiped ? false : true);

    const ref_user = useRef(null);
    const ref_content = useRef(null);

    const getHeight = () => {
      let h = height-158;
      if(ref_user.current) {
        console.log("EST ref_user");
        h = height-ref_user.current.clientHeight;
        h=h-50;
      }
      return h;
    }

    useEffect(() => {
      /*let h = height-88;
      if(ref_user.current) {
        h = height-ref_user.current.clientHeight;
      }
      setHeightContent(h+'px');*/
      let display = 'flex';
      if(ref_content.current) {
        console.log("EST content");
        if (ref_content.current.scrollHeight > ref_content.current.clientHeight) {
          display = 'block';
        }
      }
      setDisplayContent(display);
      //console.log('Height: '+ref_content.current.clientHeight+' scroll: '+ ref_content.current.scrollHeight +' offset '+ ref_content.current.offsetHeight);
    }, []);

    const readMore = ()=>{
      onChangeSwipe(false);
    }

    useEffect(() => {
      if (swiped) {
        setDisplayOverflow('hidden');
        setRead(false);
      } else {
        setRead(true);
        setDisplayOverflow('auto');
      }
    }, [swiped]);

    const readStop = ()=>{
      onChangeSwipe(true);
    }

    return (
      <div className="content-text">
        {finish ? (
          <div>
            <Link to={url(``)} replace={true}><BiBarChartAlt2 className="nav-icon" onClick={handleClick}/></Link>
            <BiBarChartAlt2 className="nav-icon" onClick={handleClick}/>
          </div>
          ) : (
          <div>
            <PostUser ref={ref_user} author={reaction.user} scope={reaction.scope}/>
            <div className="reaction_content" ref={ref_content} style={{ height: getHeight()+'px', display: display_content, overflowY: display_overflow }} >
              {/*JSON.stringify(reaction)*/}
              {parse(reaction.text_msg)}
            </div>
            {display_content=='block' &&
              <div>
                {!read &&
                  <div className="reaction_read" onClick={readMore}>Читать полностью</div>
                }
                {read &&
                  <div className="reaction_read" onClick={readStop}>Прочитал</div>
                }
              </div>
            }
            <ControlPanel />
          </div>
        )}
      </div>
    )
};