import React from 'react';
import { Layout, Space, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AuthButtonGoogle from '../../components/AuthButtonGoogle';
import AuthButtonApple from '../../components/AuthButtonApple';
import WiwHeaderTitle from '../../components/WiwHeaderTitle';
import { HomeOutlined } from '@ant-design/icons';
import './index.scss';

const { Content } = Layout;

export default function Login() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
          <Layout className="login_page">
          <WiwHeaderTitle title={t('login.title')}></WiwHeaderTitle>
          <Content className="content-layot">
            <Row type="flex" justify="center" align="middle" style={{minHeight: '100%'}}>
              <Space direction="vertical">
              <AuthButtonGoogle/>
              <AuthButtonApple/>
              <Button style={{width: '100%'}} className='button_border' onClick={() => navigate(-1)} icon={<HomeOutlined />}>{t('login.button_skip')}</Button>
              </Space>
            </Row>
          </Content>
          </Layout>
    )
}