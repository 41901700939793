import React, { useEffect } from 'react';
import useFetch from "../../hooks/useFetch";
import { APP_ROUTES } from '../../utils/constant';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppleFilled }  from '@ant-design/icons';

export default function AuthButtonApple() {

  const { t } = useTranslation();
  const { handleLogin, loading, error } = useFetch('apple');

    useEffect(() => {
      initApple();
      const readMessage = evt => {
  
        if (evt.origin !== "https://appleid.apple.com")
            return;
        if (!evt.data)
            return;
          console.log(evt.data);
        const data = JSON.parse(evt.data);
        switch (data.method) {
            case "oauthDone":
                evt.source.close();
                (data.error) ? console.log(' error ', data.error): handleLogin({ credential: data.data.authorization.id_token });
                window.removeEventListener("message", readMessage);
                break;
            default:
        }
      };
  
      window.addEventListener('message', readMessage);
  
      return () => {
        window.removeEventListener('message', readMessage);
      };
    }, [handleLogin]);

    const initApple = () => {
      window.AppleID.auth.init({
        clientId: process.env.REACT_APP_APPLE_CLIENT_ID, // This is the service ID we created.
        scope: "name email", // To tell apple we want the user name and emails fields in the response it sends us.
        //redirectURI: process.env.REACT_APP_API_URL+"/user/login_apple", // As registered along with our service ID
        redirectURI: window.location.protocol + "//" + window.location.host+APP_ROUTES.LOGIN, // As registered along with our service ID
        state: "origin:web", // Any string of your choice that you may use for some logic. It's optional and you may omit it.
        usePopup: true, // Important if we want to capture the data apple sends on the client side.
      });
    };

    return (
      <div style={{width: '100%'}}>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {loading ? <div>Loading....</div> : <Button style={{width: '100%'}} className='button_border' icon={<AppleFilled />} onClick={() => window.AppleID.auth.signIn()}>{t('login.button_apple')}</Button>}
      </div>
    );
}