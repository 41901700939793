import React from "react";
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import { BiBarChartAlt2 } from 'react-icons/bi';
import { MdOutlineSupervisedUserCircle } from 'react-icons/md';
import { APP_ROUTES } from '../../utils/constant';
import url from '../../utils/url';
import './index.scss';

const PostTitle = ({postId, title, changeSection, finish}) => {

  const clickButton = (e,section) => {
    e.preventDefault()
    changeSection(section);
  }

  return (
    <div className="postTitle">
      <Row type="flex" style={{ alignItems: 'center' }} justify="center">
        <Col className="align-center" span={3}>
          { !finish &&
            <Link to={url(`${APP_ROUTES.POST}/${postId}/statistic`)} onClick={(e) => clickButton(e,'statistic')}><BiBarChartAlt2 className="nav-icon" /></Link>
          }
        </Col>
        <Col className="align-center" span={18} onClick={(e) => clickButton(e,'')}>
          <div className="header-title">{title}</div>
        </Col>
        <Col className="align-center" span={3}>
          { !finish &&
          <Link to={url(`${APP_ROUTES.POST}/${postId}/reaction`)} onClick={(e) => clickButton(e,'reaction')}><MdOutlineSupervisedUserCircle className="nav-icon" /></Link>
          }
        </Col>
      </Row>
    </div>
  )
}

export default PostTitle;