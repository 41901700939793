import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import { Input } from 'antd';
import { APP_ROUTES } from '../../utils/constant';
import PostUser from '../PostUser';
import ControlPanel from '../WiwFooter/ControlPanel';
import { getUser } from '../../utils/func';
import url from '../../utils/url';
import api from '../../services';
import { useTranslation } from 'react-i18next';
import config from '../../utils/config';
import imageCompression from 'browser-image-compression';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";

const { TextArea } = Input;
const { queryPostVoting } = api;
//import './index.scss';

export default function PostReactionAdd({storyId, reactionId, scope}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = getUser();
  const [keyComment] = useState('comment'+storyId);
  const [comment, setComment] = useState('');
  const [img, setImg] = useState('');

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleChange = (data) => {
    setEditorState(data);
  };
  var htmlData = useMemo(
    () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
    [editorState]
  );

  const toolbarOptions = {
    options: [/*"inline", "fontSize",*/ "image", "emoji"],
    /*inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },*/
    image: {
      urlEnabled: false,
      uploadEnabled: true, 
      uploadCallback: handleImageUpload, 
      previewImage: true,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alignmentEnabled: false,
      defaultSize: {
        height: 'auto',
        width: '100%',
      },
    },
  };

  useEffect(() => {
    if (user === null) {
      navigate(url(`${APP_ROUTES.LOGIN}?from=${window.location.pathname}`), { replace: true });   
    }
    if (localStorage.getItem(keyComment)) {
      setComment(localStorage.getItem(keyComment));
    }
  },[])

  const sendVoting = async () => {
    const privat_flg = (localStorage.getItem(config.privat)) ? (localStorage.getItem(config.privat) === "true") : false;
    const response = await queryPostVoting({post_id: Number(storyId), parent_reaction: reactionId, score: scope, txt_message: comment, privat: privat_flg});
    if (response.statusCode == 401) {
      navigate(url(APP_ROUTES.LOGIN+'?from='+window.location.pathname), { replace: true });
      return;
    }
    localStorage.removeItem(keyComment);
    navigate(url(APP_ROUTES.REACTION+'/'+response.id), { replace: true });
    console.log(response);
  }

  const cancel = ()=>{
    navigate(-1)
  }

  const onChangeComment = (e) => {
    setComment(e.target.value);
    localStorage.setItem(keyComment, e.target.value);
  }

  useEffect(() => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setComment(html);
    localStorage.setItem(keyComment, html);
  },[editorState]);

  async function handleImageUpload(/*event*/ imageFile) {

    //const imageFile = event.target.files[0];
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    
  
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      console.log(compressedFile);
      const base = await imageCompression.getDataUrlFromFile(compressedFile);
      console.log(base);
      setImg(base);

      return new Promise((resolve) => {
        resolve({
          data: {
            link: base,
          },
        });
      });
      
      //await uploadToServer(compressedFile); // write your own logic
    } catch (error) {
      console.log(error);
    }
  
  }

  return (
    <div className="content-text">
      {user!==null && 
        <div className="addCommentPage">
          <PostUser author={user} scope={scope}/>
          {/*<TextArea className="addCommentText" value={comment} placeholder={t('post.add_comment_text')} onChange={onChangeComment}/>*/}
        <Editor
          editorState={editorState}
          onEditorStateChange={handleChange}
          wrapperClassName="editor-wrapper"
          editorClassName="message-editor"
          toolbarClassName="message-toolbar"
          toolbar={toolbarOptions}
        />
        {/*<div className="html-output">{comment}</div>*/}
        </div>
      }
      
      {/*<input type="file" accept="image/*" onChange={event => handleImageUpload(event)}/>
      <img src={img} style={{width: '100%'}}/>*/}
      <ControlPanel leftBtn="pensil" rightBtn="send" onLeftAction={cancel} onRightAction={sendVoting} />
    </div>
  )
}