import axios from 'axios'
import { cloneDeep } from 'lodash'
import { message } from 'antd'
import { CANCEL_REQUEST_MESSAGE } from './constant'
import config from './config';
import { getLanguage, generateFToken } from './func';
//import { useNavigate } from 'react-router-dom';
const { parse, compile } = require("path-to-regexp")


const { CancelToken } = axios
window.cancelRequest = new Map()

export default function request(options) {
  //const navigate = useNavigate();
  let { data, url } = options
  const cloneData = cloneDeep(data)

  try {
    let domain = ''
    const urlMatch = url.match(/[a-zA-z]+:\/\/[^/]*/)
    if (urlMatch) {
      ;[domain] = urlMatch
      url = url.slice(domain.length)
    }

    const match = parse(url)
    url = compile(url)(data)

    for (const item of match) {
      if (item instanceof Object && item.name in cloneData) {
        delete cloneData[item.name]
      }
    }
    url = domain + url
  } catch (e) {
    message.error(e.message)
  }

  options.url = url
  options.cancelToken = new CancelToken(cancel => {
    window.cancelRequest.set(Symbol(Date.now()), {
      pathname: window.location.pathname,
      cancel,
    })
  })

  //добавляем в заголовки выбранный язык на фронте
  let headers = {
    [config.lng]: getLanguage()
  }

  //если в localStorage есть token, то добавляем его в заголовки
  if (localStorage.getItem(config.token)) {
    headers[config.token]= localStorage.getItem(config.token)
  }

  //если в localStorage есть ftoken, то добавляем его в заголовки. Если нет, то создаём, сохраняем в localStorage, добавляем в заголовки
  if (localStorage.getItem(config.ftoken)) {
    headers[config.ftoken]= localStorage.getItem(config.ftoken)
  } else {
    const ftoken = generateFToken();
    localStorage.setItem(config.ftoken, ftoken);
    headers[config.ftoken]= ftoken
  }

  options.headers = headers;
  
  return axios(options)
    .then(response => {

      const { statusText, status, data } = response

      let result = {}
      if (typeof data === 'object') {
        result = data
        if (Array.isArray(data)) {
          result.list = data
        }
      } else {
        result.data = data
      }

      return Promise.resolve({
        success: true,
        message: statusText,
        statusCode: status,
        ...result,
      })
    })
    .catch(error => {
      const { response, message } = error

      if (String(message) === CANCEL_REQUEST_MESSAGE) {
        return {
          success: false,
        }
      }

      let msg
      let statusCode

      if (response && response instanceof Object) {
        const { data, statusText } = response
        statusCode = response.status
        msg = data.message || statusText
        if (statusCode===401) {
            console.log("401");
            //navigate('/login?from='+window.location.pathname, { replace: true });
            //window.location.href = '/login?from='+window.location.pathname;
        }
      } else {
        statusCode = 600
        msg = error.message || 'Network Error'
      }

      /* eslint-disable */
      return Promise.resolve({
        success: false,
        statusCode,
        message: msg,
      })
    })
}